<template>
  <div class="Featured" v-show="mode !== 'Delivery'">
    <div class="Featured-header">
      <h1>Gourmet Featured</h1>
      <p>Experience the Fresh Taste At A Great Price, Exclusively Available For Those Who Love Delicious Meals And Are
        Always Hungry For More!</p>
    </div>
    <div v-if="!featured_products || featured_products.length === 0" class="coming-soon-div text-center">
        <img src="./home-converted/coming-soon.webp" alt="Coming Soon">
      </div>
    <div v-else class="Featureds-cards">
      <div class="Featureds-card" v-for="product in featured_products" :key="product.id">
        <div class="Featureds-card-bg">
          <h1>{{ product.title }}</h1>
          <h5>Price: ${{ product.price }}</h5>
          <div class="Featureds-card-btn" role="button" @click="addToCart(product.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <path
                  d="M5.73475 0C5.89474 0 6.05585 0.0455537 6.19917 0.142217C7.56468 1.06107 10.7446 3.37429 10.7446 5.00201C10.7446 6.62973 7.56468 8.94075 6.19806 9.8585C5.81586 10.1152 5.2981 10.0129 5.04255 9.63073C4.78589 9.24852 4.887 8.73076 5.26921 8.4741C6.57494 7.59647 7.80356 6.57717 8.49742 5.83542H0.833303C0.37332 5.83542 0 5.4621 0 5.00212C0 4.54213 0.37332 4.16882 0.833303 4.16882H8.50642C7.81412 3.43318 6.58027 2.40736 5.2681 1.52439C4.88589 1.26773 4.78589 0.749973 5.04255 0.367764C5.20254 0.128884 5.46698 0 5.73475 0Z"
                  fill="#FFF"/>
            </svg>
            Add to Cart
          </div>
          <div class="Featureds-card-shok-icon"></div>
          <img :src="product.image" alt="">

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['featureds'],
  data() {
    return {
      featured_products: JSON.parse(this.featureds),
      mode: ''
    };
  },
  mounted() {
    this.emitter.on('isPickup', (data) => {
      this.mode = data;
      // Handle Pickup-specific logic here
    });

    this.emitter.on('isDelivery', (data) => {
      this.mode = data;
      // Handle Delivery-specific logic here
    });
  },
  beforeUnmount() {
    this.emitter.off('isPickup');
    this.emitter.off('isDelivery');
  },
  methods: {
    addToCart(item) {
      // Replace 'your-api-endpoint' with the actual endpoint URL
      const url = `/update-cart-featured/${item}`;

      // Make the GET request
      axios.get(url)
          .then(response => {
            // Log the item (response data)
            console.log(response.data);

            // Emit an event to update the cart with the received product data
            this.emitter.emit('add-to-cart', response.data);
            this.$toast.success('Item successfully added to cart!', {
                duration: 4000, // Optional: duration in milliseconds
                position: 'bottom'
            });
          })
          .catch(error => {
            console.error('There was an error fetching the product data:', error);
          });
    },

  }
};
</script>

<style scoped>
.Featured {
  padding: 80px;
}

.Featured-header {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
}

.Featured-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 12px;
}

.Featured-header p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  margin: 0;
}

.Featureds-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 80px;

}

.Featureds-card {
  width: 23.125rem;
  background: white;
  height: 492px;
  border-radius: 20px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;

  /* position: relative; */
}

.Featureds-card-bg {
  background: url('./Featured-image/Featured-card-bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Featureds-card-bg h1 {
  margin-top: 60px;
  margin-bottom: 15px;
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
}

.Featureds-card-bg h5 {
  margin-bottom: 50px;
  color: #FFF;
}

.Featureds-card .Featureds-card-btn {
  padding: 12px 18px;
  border-radius: 50px;
  border: 2px solid #FFF;
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  letter-spacing: -0.7px;
  text-transform: uppercase;
  gap: 10px;
  text-decoration: none;
}


.Featureds-card img {
  position: absolute;
  bottom: -15%;
  width: 300px;
  height: 300px;
  transition: 0.8s;
  border-radius: 50%;
}

.Featureds-card:hover img {
  transform: rotate(90deg);
}

.Featureds-card-shok-icon::after {
  content: '';
  background: url('./Featured-image/Featured-card-shok-icon-one.png');
  position: absolute;
  width: 111px;
  height: 92px;
  top: 40%;
  left: 63%;
  background-repeat: no-repeat;
  transform: rotate(-108deg);
  z-index: 1;
  opacity: 0;
  transition: 0.8s;

}

.Featureds-card:hover .Featureds-card-shok-icon::after {
  opacity: 1;
}


.Featureds-card-shok-icon::before {
  content: '';
  background: url('./Featured-image/Featured-card-shok-icon-two.png');
  position: absolute;
  width: 276px;
  height: 92px;
  top: 59%;
  left: -20%;
  background-repeat: no-repeat;
  transform: rotate(98deg);
  z-index: 1;
  opacity: 0;
  transition: 0.8s;
}

.Featureds-card:hover .Featureds-card-shok-icon::before {
  opacity: 1;
}

@media only screen  and (max-width: 599px) {
  .Featured {
    padding: 20px;
  }
  .coming-soon-div img {
    width: 19rem;
    height: 16rem;
  }
  .Featureds-card {
    width: 21.25rem;
  }
}

@media only screen  and (max-width: 991px) {

  .Featured-header {
    width: 85%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
  }

  .Featured-header h1 {
    font-size: 28px;
  }

  .Featureds-cards {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}


@media only screen and (min-width: 600px) and (max-width: 991px) {
  .Featured {
    padding: 40px;
  }

  .Featured-header {
    width: 85%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .Featured {
    padding: 80px 40px;
  }

  .Featureds-card {
    gap: 30px;
  }


  .Featureds-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .Featureds-cards {
    width: 21.875rem;
  }

  .Featureds-card {
    gap: 40px;
  }
}</style>
