<template>
  <div class="hero-area position-relative">
    <video class="video-background" autoplay loop muted playsinline load>
      <source src="./Heroarea-Image/enimation-gif.webm" type="video/webm">
      Your browser does not support the video tag.
    </video>
    <div class="div-content">
      <h1>"Freshness, Flavor, Tradition"™️</h1>

      <a href="#menu" class="hero-btn d-flex">
        Order Now
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="28" viewBox="0 0 40 28" fill="none">
          <path d="M37.5586 14L1.55859 14M37.5586 14L25.5586 2M37.5586 14L25.5586 26" stroke="white" stroke-width="3"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>

    </div>
    <div class="position-absolute translate-middle hero-enimation-img">
      <img src="./Heroarea-Image/hero-spin.png" alt="" loading="lazy">
    </div>

  </div>
  <StaticBackdropModal />
</template>

<script>
import StaticBackdropModal from './StaticBackdropModal';

export default {
  components: {
    StaticBackdropModal,
  }
};
</script>

<style>
.hero-area {
  width: 100%;
  height: 604px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(105, 18, 9, 0.20), rgba(105, 18, 9, 0.20)), url('./Heroarea-Image/enimation-gif.webm');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 126px;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: -1; /* Ensure it stays behind the content */
}

.div-content {
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-content h1 {
  color: #FFF;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 64.8px */
  margin-bottom: 72px;

}

.hero-btn {
  width: 181px;
  align-items: center;
  padding: 20px 40px;
  gap: 17px;
  border-radius: 8px;
  background: #691209;
  box-shadow: 0px 10px 30px 0px rgba(202, 66, 17, 0.10);
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s;
}

.hero-btn > svg {
  position: absolute;
  transform: translateX(85px);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}


.hero-btn:hover {
  transform: scale(1.1);
  background: #691209;
  width: 230px;

}

.hero-btn:hover svg {
  transform: translateX(120px);
  opacity: 1;
  visibility: visible;
}


.hero-enimation-img {
  top: 100%;
  left: 90%;
}

.hero-enimation-img img {
  animation: spin 16s linear infinite;
  -webkit-animation: spin 16s linear infinite;
  -moz-animation: spin 16s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@media only screen and (max-width: 599px) {
  .div-content h1 {
    font-size: 24px;
  }


  .hero-btn svg {
    width: 50px;
  }

  .hero-enimation-img img {
    width: 80px;
  }

  .hero-enimation-img {
    top: 100%;
    left: 84%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .div-content h1 {
    font-size: 40px;
  }

  .hero-btn {

    font-size: 18px;
  }


  .hero-btn svg {
    width: 55px;
  }

  .hero-enimation-img img {
    width: 100px;
  }

  .hero-enimation-img {
    top: 100%;
    left: 86%;
  }

}
</style>
