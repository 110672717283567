import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BootstrapVueNext } from 'bootstrap-vue-next';
import { createManager } from '@vue-youtube/core';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import mitt from 'mitt';
const emitter = mitt();

import HelloWorld from './components/HelloWorld.vue'
import NavBar from './components/NavBar.vue'
import FooterComp from './components/FooterComp.vue'
import HeroArea from './components/HeroArea.vue'
import CreateOwn from './components/CreateOwn.vue'
import FoodDelivery from './components/FoodDelivery.vue'
import AppSection from './components/AppSection.vue'
import NewsLetter from './components/NewsLetter.vue'
import FeaturedSection from './components/FeaturedSection.vue'
import ContactPage from './components/ContactPage.vue'
import SignupPage from './components/SignupPage.vue'
import LoginPage from './components/LoginPage.vue'
import VerifyPage from './components/VerifyPage.vue'
import RewardPage from './components/RewardPage.vue'
import CreateOwnPage from './components/CreateOwnPage.vue'
import CateringPage from './components/CateringPage.vue'
import CheckoutPage from './components/CheckoutPage.vue'
import OurBelife from './components/OurBelife.vue'
import OrderStatus from './components/OrderStatus.vue'
import PaymentForm from './components/PaymentForm.vue'
import CateringSection from './components/CateringSection.vue'
import FeedBack from "./components/FeedBack.vue"
import ForgetPassword from "./components/ForgetPassword.vue"
import SetNewPassword from "./components/SetNewPassword.vue"
import VerifyPhoneForgetPassword from "./components/VerifyPhoneForgetPassword.vue"
import VariantShow from "./components/VariantShow.vue"
import SaladMenu from "./components/SaladMenu.vue";
import KidsMenu from "./components/KidsMenu.vue";
import SidesSection from "./components/SidesSection.vue";
import OtherOrders from "./components/OtherOrders.vue";
import SaladVariant from "./components/SaladVariant.vue";
import OnlyProduct from "./components/OnlyProduct.vue";
import OrderTypeSelection from "@/templates/assets/js/components/OrderTypeSelection";
const app = createApp({})

app.component('hello-world', HelloWorld)
app.component('nav-bar', NavBar)
app.component('footer-comp', FooterComp)
app.component('hero-area', HeroArea)
app.component('create-item', CreateOwn)
app.component('food-delivery', FoodDelivery)
app.component('app-section', AppSection)
app.component('news-letter', NewsLetter)
app.component('featured-section', FeaturedSection)
app.component('contact-page', ContactPage)
app.component('sign-up', SignupPage)
app.component('log-in', LoginPage)
app.component('verify-page', VerifyPage)
app.component('reward-page', RewardPage)
app.component('create-own-page', CreateOwnPage)
app.component('catering-page', CateringPage)
app.component('check-out', CheckoutPage)
app.component('our-belief', OurBelife)
app.component('order-status', OrderStatus)
app.component('payment-form', PaymentForm)
app.component('catering-section', CateringSection)
app.component('feed-back', FeedBack)
app.component('variant-show', VariantShow)
app.component('forget-password', ForgetPassword)
app.component('set-new-password', SetNewPassword)
app.component('verify-phone-forget-password', VerifyPhoneForgetPassword)
app.component('salad-menu', SaladMenu)
app.component('kids-menu', KidsMenu)
app.component('sides-menu', SidesSection)
app.component('other-orders', OtherOrders)
app.component('salad-variant', SaladVariant)
app.component('only-product', OnlyProduct)
app.component('order-type-selection', OrderTypeSelection)


app.use(BootstrapVueNext);
app.use(createManager());
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDZRTKcPUIVkcLWHjCXMqPMTZEUKncoLEk',
        libraries: "places"
    },
});
app.use(VueToast);
app.config.globalProperties.emitter = emitter;

app.mount('body')