<template>
  <div class="Featured" v-show="mode !== 'Delivery'">
    <div class="Featured-header">
      <h1>Kid’s Menu</h1>
      <p>Customize your perfect meal with choice of base, proteins, veggies, toppings, dressings, and drinks</p>
    </div>
    <div v-if="!parsed_products || parsed_products.length === 0" class="coming-soon-div text-center">
      <img src="./home-converted/coming-soon.webp" alt="Coming Soon">
    </div>
    <div v-else class="Featureds-cards">
      <div class="Featureds-card" v-for="(product, index) in parsed_products" :key="product.id">
        <div class="Featureds-card-bg">
          <h1>{{ product.title }}</h1>
          <h5>Price: ${{ product.price }}</h5>
          <div class="Featureds-card-btn" role="button" @click="toggleVisibility(index)">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <path
                  d="M5.73475 0C5.89474 0 6.05585 0.0455537 6.19917 0.142217C7.56468 1.06107 10.7446 3.37429 10.7446 5.00201C10.7446 6.62973 7.56468 8.94075 6.19806 9.8585C5.81586 10.1152 5.2981 10.0129 5.04255 9.63073C4.78589 9.24852 4.887 8.73076 5.26921 8.4741C6.57494 7.59647 7.80356 6.57717 8.49742 5.83542H0.833303C0.37332 5.83542 0 5.4621 0 5.00212C0 4.54213 0.37332 4.16882 0.833303 4.16882H8.50642C7.81412 3.43318 6.58027 2.40736 5.2681 1.52439C4.88589 1.26773 4.78589 0.749973 5.04255 0.367764C5.20254 0.128884 5.46698 0 5.73475 0Z"
                  fill="#FFF"/>
            </svg>
            Order Now
          </div>
          <div v-if="product.isVisible" class="modal show" tabindex="-1" role="dialog"
               style="display: block; backdrop-filter: blur(2px);">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title">{{ product.title }}</h1>
                  <button type="button" class="close" @click="toggleVisibility(index)">
                  <span aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                      <path
                          d="M7.31382 5.89982L10.8488 2.36382C10.9443 2.27157 11.0205 2.16123 11.0729 2.03922C11.1253 1.91722 11.1529 1.786 11.1541 1.65322C11.1552 1.52044 11.1299 1.38876 11.0796 1.26587C11.0294 1.14297 10.9551 1.03132 10.8612 0.937425C10.7673 0.843532 10.6557 0.769279 10.5328 0.718998C10.4099 0.668717 10.2782 0.643415 10.1454 0.644569C10.0126 0.645723 9.88142 0.673309 9.75942 0.725718C9.63741 0.778127 9.52707 0.854309 9.43482 0.949819L5.89882 4.48482L2.36382 0.949819C2.27157 0.854309 2.16123 0.778127 2.03922 0.725718C1.91722 0.673309 1.786 0.645723 1.65322 0.644569C1.52044 0.643415 1.38876 0.668717 1.26587 0.718998C1.14297 0.769279 1.03132 0.843532 0.937425 0.937425C0.843532 1.03132 0.769279 1.14297 0.718998 1.26587C0.668717 1.38876 0.643415 1.52044 0.644569 1.65322C0.645723 1.786 0.673309 1.91722 0.725718 2.03922C0.778127 2.16123 0.854309 2.27157 0.949819 2.36382L4.48482 5.89882L0.949819 9.43482C0.854309 9.52707 0.778127 9.63741 0.725718 9.75942C0.673309 9.88142 0.645723 10.0126 0.644569 10.1454C0.643415 10.2782 0.668717 10.4099 0.718998 10.5328C0.769279 10.6557 0.843532 10.7673 0.937425 10.8612C1.03132 10.9551 1.14297 11.0294 1.26587 11.0796C1.38876 11.1299 1.52044 11.1552 1.65322 11.1541C1.786 11.1529 1.91722 11.1253 2.03922 11.0729C2.16123 11.0205 2.27157 10.9443 2.36382 10.8488L5.89882 7.31382L9.43482 10.8488C9.52707 10.9443 9.63741 11.0205 9.75942 11.0729C9.88142 11.1253 10.0126 11.1529 10.1454 11.1541C10.2782 11.1552 10.4099 11.1299 10.5328 11.0796C10.6557 11.0294 10.7673 10.9551 10.8612 10.8612C10.9551 10.7673 11.0294 10.6557 11.0796 10.5328C11.1299 10.4099 11.1552 10.2782 11.1541 10.1454C11.1529 10.0126 11.1253 9.88142 11.0729 9.75942C11.0205 9.63741 10.9443 9.52707 10.8488 9.43482L7.31382 5.89882V5.89982Z"
                          fill="#691209"/>
                    </svg>
                  </span>
                  </button>
                </div>
                <div class="modal-body">
                  <other-orders :product="product"></other-orders>
                </div>
              </div>
            </div>
          </div>
          <div class="Featureds-card-shok-icon"></div>
          <img :src="product.image" alt="">

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import OtherOrders from "@/templates/assets/js/components/OtherOrders";

export default {
  components: {OtherOrders},
  props: ['products'],
  data() {
    return {
      parsed_products: JSON.parse(this.products).map(product => ({
        ...product,
        isVisible: false
      })),
      mode: ''
    };
  },
  mounted() {
    this.emitter.on('isPickup', (data) => {
      this.mode = data;
      // Handle Pickup-specific logic here
    });

    this.emitter.on('isDelivery', (data) => {
      this.mode = data;
      // Handle Delivery-specific logic here
    });
  },
  beforeUnmount() {
    this.emitter.off('isPickup');
    this.emitter.off('isDelivery');
  },
  methods: {
    toggleVisibility(index) {
      this.parsed_products[index].isVisible = !this.parsed_products[index].isVisible;
      const anyModalOpen = this.parsed_products.some(product => product.isVisible);

      if (anyModalOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    addToCart(item) {
      console.log(this.products)
      // Replace 'your-api-endpoint' with the actual endpoint URL
      const url = `/update-cart-featured/${item}`;

      // Make the GET request
      axios.get(url)
          .then(response => {
            // Log the item (response data)
            console.log(response.data);

            // Emit an event to update the cart with the received product data
            this.emitter.emit('add-to-cart', response.data);
          })
          .catch(error => {
            console.error('There was an error fetching the product data:', error);
          });
    },

  }
};
</script>

<style scoped>
/* Kids Menu Style */
.modal-dialog {
    max-width: 50rem !important;
    min-width: 22.5rem !important;
}
.modal-dialog .modal-header {
  justify-content: space-between;
}
.modal-dialog .modal-header h1 {
  margin: 0px !important;
  color: #691209 !important;
}

.modal-dialog button {
  width: fit-content !important;
  background: none;

}
.Featured {
  padding: 80px;
}

.Featured-header {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
}

.Featured-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 12px;
}

.Featured-header p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  margin: 0;
}

.Featureds-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 80px;

}

.Featureds-card {
  width: 23.125rem;
  background: white;
  height: 492px;
  border-radius: 20px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;

  /* position: relative; */
}

.Featureds-card-bg {
  background: url('./Featured-image/Featured-card-bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Featureds-card-bg h1 {
  margin-top: 60px;
  margin-bottom: 15px;
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
}

.Featureds-card-bg h5 {
  margin-bottom: 50px;
  color: #FFF;
}

.Featureds-card .Featureds-card-btn {
  padding: 12px 18px;
  border-radius: 50px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  letter-spacing: -0.7px;
  text-transform: uppercase;
  gap: 10px;
  text-decoration: none;
}


.Featureds-card img {
  position: absolute;
  bottom: -15%;
  width: 300px;
  height: 300px;
  transition: 0.8s;
  border-radius: 50%;
  object-fit: fill;
}

.Featureds-card:hover img {
  transform: rotate(90deg);
}

.Featureds-card-shok-icon::after {
  content: '';
  background: url('./Featured-image/Featured-card-shok-icon-one.png');
  position: absolute;
  width: 111px;
  height: 92px;
  top: 40%;
  left: 63%;
  background-repeat: no-repeat;
  transform: rotate(-108deg);
  z-index: 1;
  opacity: 0;
  transition: 0.8s;

}

.Featureds-card:hover .Featureds-card-shok-icon::after {
  opacity: 1;
}


.Featureds-card-shok-icon::before {
  content: '';
  background: url('./Featured-image/Featured-card-shok-icon-two.png');
  position: absolute;
  width: 276px;
  height: 92px;
  top: 59%;
  left: -20%;
  background-repeat: no-repeat;
  transform: rotate(98deg);
  z-index: 1;
  opacity: 0;
  transition: 0.8s;
}

.Featureds-card:hover .Featureds-card-shok-icon::before {
  opacity: 1;
}

@media only screen  and (max-width: 599px) {
  .Featured {
    padding: 20px;
  }

  .coming-soon-div img {
    width: 19rem;
    height: 16rem;
  }

  .Featureds-card {
    width: 21.125rem;
  }
}

@media only screen  and (max-width: 991px) {

  .Featured-header {
    width: 85%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
  }

  .Featured-header h1 {
    font-size: 28px;
  }

  .Featureds-cards {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}


@media only screen and (min-width: 600px) and (max-width: 991px) {
  .Featured {
    padding: 40px;
  }

  .Featured-header {
    width: 85%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .Featured {
    padding: 80px 40px;
  }

  .Featureds-card {
    gap: 30px;
  }


  .Featureds-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .Featureds-cards {
    width: 353px;
  }

  .Featureds-card {
    gap: 40px;
  }
}</style>
