<template>
  <div class="carousel" v-show="mode !== 'Delivery'">
    <div class="carousel-content d-flex justify-content-center align-items-center">
      <div class="carousel_header">
        <h1>Sides</h1>
        <p>Choose from a variety of delicious sides, available in two different sizes to complement your meal.</p>
      </div>
    </div>
    <Carousel :items-to-show="itemsToShow" :wrap-around="wrapAround" style="margin-bottom: 5rem;">
      <Slide v-for="(product) in parsed_products" :key="product.id">
        <div class="carousel__item">
          <img :src="product.image" alt="Product Image">
          <div class="items_name d-flex flex-column gap-3 align-items-center">
            <h2>{{ product.title }}</h2>
            <p>{{ product.description }}</p>
            <div class="slide_item_price">
              <input class="d-none" type="radio" :id="'price1-' + product.id" value="price1" v-model="product.picked"/>
              <label :for="'price1-' + product.id">{{ product.price_1 }} $ - {{ product.price_1_title }}</label>

              <input class="d-none" type="radio" :id="'price2-' + product.id" value="price2" v-model="product.picked"/>
              <label :for="'price2-' + product.id">{{ product.price_2 }} $ - {{ product.price_2_title }}</label>
            </div>
            <button class="order_btn" @click="addToCart(product)" :disabled="!product.picked">Order Now</button>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import axios from "axios";

export default defineComponent({
  name: 'WrapAround',
  props: ['products'],
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      parsed_products: JSON.parse(this.products).map(product => ({
        ...product,
        picked: null // Add a picked attribute to each product
      })),
      itemsToShow: 1, // Default value for larger screens
      wrapAround: window.innerWidth >= 599,
      mode: ''
    };
  },
  methods: {
    addToCart(item) {
      let size, price;

      // Determine which price option was selected
      if (item.picked === 'price1') {
        size = item.price_1_title;
        price = item.price_1;
      } else if (item.picked === 'price2') {
        size = item.price_2_title;
        price = item.price_2;
      }

      const url = `/update-cart-side/${item.id}?size=${size}&price=${price}`;

      // Make the GET request
      axios.get(url)
        .then(response => {
          // Log the item (response data)
          console.log(response.data);

          // Emit an event to update the cart with the received product data
          this.emitter.emit('add-to-cart', response.data);
          this.$toast.success('Item successfully added to cart!', {
                duration: 4000, // Optional: duration in milliseconds
                position: 'bottom'
            });
        })
        .catch(error => {
          console.error('There was an error fetching the product data:', error);
        });
    },
    updateItemsToShow() {
      if (window.innerWidth < 599) {
        this.itemsToShow = 1; // Show only one item
        this.wrapAround = false; // Disable wrap-around
      } else {
        this.itemsToShow = 2.5; // Show 2.5 items for larger screens
        this.wrapAround = true; // Enable wrap-around
      }
    }
  },
  mounted() {
    this.updateItemsToShow(); // Set initial value based on screen width
    window.addEventListener('resize', this.updateItemsToShow); // Listen for screen resize
    this.emitter.on('isPickup', (data) => {
      this.mode = data;
      // Handle Pickup-specific logic here
    });

    this.emitter.on('isDelivery', (data) => {
      this.mode = data;
      // Handle Delivery-specific logic here
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsToShow); // Cleanup event listener
    this.emitter.off('isPickup');
    this.emitter.off('isDelivery');
  }
})
</script>

<style scoped>
.carousel__track {
  margin: 0 !important;
}
.carousel_header {
  text-align: center;
  margin-bottom: 80px;
}

.carousel_header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 10px;
}

.carousel_header p {
  color: #333;
  text-align: center;
  width: 600px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}

.cards-wrapper {
  display: flex;

}

.card {
  margin: 1em;
  width: calc(100% / 3);
}

.slide_item_price input:checked + label {
  border-radius: 6px;
  border: 1px solid #691209;
  background: #691209;
  color: #FFF;
}

.carousel__item img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}


.carousel__slide {
  padding: 10px;
  width: 50% !important;
  border-radius: 20px;
  border: 1px solid #E7EAF3;
  background: #FFFDFD;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}


.slide_item_price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.slide_item_price label {
  border-radius: 6px;
  border: 1px solid #691209;
  padding: 10px;
  color: #691209;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 77.778% */
  text-transform: capitalize;
  text-wrap: nowrap;
}

.items_name h2 {
  color: #691209;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */

}

.items_name p {
  color: #4A4A4A;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.order_btn {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
  padding: 14px 20px;
  border-radius: 50px;
  background: #691209;
}


@media only screen and (max-width: 599px) {
  .carousel{
    padding: 0px;
  }

  .carousel__item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carousel__item > img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
  }

  .carousel_header p {
    width: auto !important;
  }

  .slide_item_price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .slide_item_price label {
    width: 100%;
  }

  .order_btn {
    display: block;
    margin: auto;
  }
  .carousel__slide{
    width: 100% !important;
  }

}


@media only screen and (min-width: 600px) and (max-width: 991px) {
  .carousel__item {
    flex-direction: column;
    align-items: center;
  }

  .carousel__item > img {
    width: 9.375rem !important;
    height: 9.375rem !important;
    border-radius: 50% !important;
  }

  .carousel_header p {
    width: auto !important;
  }
  .slide_item_price {
    flex-direction: column;
  }
  .slide_item_price label {
    width: 100%;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .carousel__item > img {
    width: 9.375rem !important;
    height: 9.375rem !important;
    border-radius: 50% !important;
  }
  .slide_item_price {
    flex-direction: column;
  }
  .slide_item_price label {
    width: 100%;
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1508px) {


}


</style>

<style>
.carousel__track {
  display: flex;
  gap: 47px;
}

.carousel__prev,
.carousel__next {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 50%;
  box-sizing: content-box;
}

.carousel__viewport {
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 599px) {
  .carousel__prev,
  .carousel__next {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid black;
    border-radius: 50%;
    box-sizing: content-box;
  }

  .carousel__track {
    display: flex;
    gap: 0px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .carousel__prev,
  .carousel__next {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid black;
    border-radius: 50%;
    box-sizing: content-box;
  }

  .carousel__track {
    display: flex;
    gap: 47px;
  }
}
</style>