<template>
  <div class="fudo-app" v-show="mode !== 'Delivery'">
    <div class="fudo-app-container d-flex">
      <div class="fudo-app-content w-50">
        <p>Coming Soon</p>
        <h1>Get Started With <br> KEEBABI Today!</h1>
        <h5>Discover food wherever and whenever and get your food delivered quickly.</h5>

        <a class="fudo-btn ">
          Coming Soon
        </a>
      </div>
      <div class="fudo-animated-img w-50 position-relative">
        <img class="fudo-animated-img-two" src="./Fudo-image/fudo-enimated-two.png" alt="">
        <img class="fudo-animated-img-four" src="./Fudo-image/fudo-enimated-four.png" alt="">
        <img class="fudo-animated-img-one" src="./Fudo-image/fudo-enimated-one.png" alt="">
<!--        <img class="fudo-animated-img-three" src="./Fudo-image/fudo-enimated-three.png" alt="">-->
<!--        <img class="fudo-animated-img-five" src="./Fudo-image/fudo-enimated-five.png" alt="">-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [],
  data() {
    return {
      mode: ''
    };
  },
  mounted() {
    this.emitter.on('isPickup', (data) => {
      this.mode = data;
      // Handle Pickup-specific logic here
    });

    this.emitter.on('isDelivery', (data) => {
      this.mode = data;
      // Handle Delivery-specific logic here
    });
  },
  beforeUnmount() {
    this.emitter.off('isPickup');
    this.emitter.off('isDelivery');
  },
};
</script>
<style>
.fudo-app {
  background: rgba(249, 159, 0, 0.70);
  padding: 80px;
}

.fudo-app-container p {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #691209;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.4px;
  /* 124.444% */
  letter-spacing: 2.88px;

}

.fudo-app-container h1 {
  color: #333;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  /* 130.435% */
  letter-spacing: 1.38px;
  margin-bottom: 15px;
}

.fudo-app-container h5 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
  margin-bottom: 181px;
}

.fudo-btn {
  padding: 11px 20px;
  border-radius: 50px;
  background: #691209;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  /* 124.444% */
  width: 201px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.fudo-animated-img img {
  position: absolute;
}

.fudo-animated-img-one {
  left: 28%;
  top: -20px;
  animation: spinx 5s infinite;
}

.fudo-animated-img-two {
  left: 60%;
  animation: spinx 5s infinite;
}

.fudo-animated-img-five {
  left: 70%;
  bottom: 50px;

}

.fudo-animated-img-four {
  left: 1%;
  top: 59%;
  animation: spinx 8s infinite;

}

.fudo-animated-img-three {
  left: 6%;
  top: -7%;
  animation: spinx 9s infinite;
}

@keyframes spinx {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }
}




@media only screen and (max-width: 599px) {
   .fudo-app {
        padding: 20px;
    }
    .fudo-app-container{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 960px;
    }
    .fudo-app-content{
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fudo-app-content h5{
        margin-bottom: 35px;
    }
    .fudo-animated-img{
        height: 100%;
    }

      .fudo-animated-img-two {
        left: 36%;
        top: 22%;
        width: 200px;
      }

    .fudo-animated-img-one {
      left: -56%;
      top: 124px;
      width: 220px;
      width: 220px;
    }


    .fudo-animated-img-five {
        left: 36%;
        top: 58%;
        width: 200px;
    }

    .fudo-animated-img-three {
        left: -84%;
        top: 18%;
        width: 200px;
    }


    .fudo-animated-img-four {
        left: -77%;
        top: 71%;
        width: 200px;
    }
    .fudo-app-container h1 {
    font-size: 28px;
    line-height: 38px
    }
    .fudo-app-container h5 {
    font-size: 14px;
    }


}








@media only screen and (min-width: 600px) and (max-width: 991px) {
   .fudo-app {
        padding: 40px;
    }
    .fudo-app-container{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 1250px;
    }
    .fudo-app-content{
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fudo-app-content h5{
        margin-bottom: 35px;
    }
    .fudo-animated-img{
        height: 100%;
    }
    .fudo-animated-img-two {
    left: 52%;
    top: 22%;
    }
    .fudo-animated-img-one {
    left: -31%;
    top: 176px;
    }
    .fudo-animated-img-three {
    left: -71%;
    top: 18%;
    }
    .fudo-animated-img-five {
    left: 69%;
    top: 58%;
    }
    .fudo-animated-img-four {
    left: -67%;
    top: 63%;
    width: 230px;

    }
}
@media only screen and (min-width: 992px) and (max-width: 1099px) {

  .fudo-app {
    background: rgba(249, 159, 0, 0.70);
    padding: 80px 40px;
  }

  .fudo-animated-img-one {
    left: 0%;
    top: -20px;
    width: 280px;
    animation: spinx 5s infinite;
  }

  .fudo-animated-img-two {
    left: 50%;
    animation: spinx 5s infinite;
    width: 260px;
  }

  .fudo-animated-img-five {
    left: 59%;
    bottom: 105px;
    width: 200px;
  }

  .fudo-animated-img-four {
    left: -35%;
    top: 53%;
    width: 280px;
    animation: spinx 8s infinite;

  }

  .fudo-animated-img-three {
    left: -21%;
    top: -7%;
    width: 300px;
    animation: spinx 9s infinite;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1299px) {

  .fudo-app {
    background: rgba(249, 159, 0, 0.70);
    padding: 80px 40px;
  }

  .fudo-animated-img-one {
    left: 0%;
    top: -20px;

    animation: spinx 5s infinite;
  }

  .fudo-animated-img-two {
    left: 50%;
    animation: spinx 5s infinite;

  }

  .fudo-animated-img-five {
    left: 59%;
    bottom: 105px;

  }

  .fudo-animated-img-four {
    left: -35%;
    top: 53%;

    animation: spinx 8s infinite;

  }

  .fudo-animated-img-three {
    left: -21%;
    top: -7%;

    animation: spinx 9s infinite;
  }
}



@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .fudo-animated-img-one {
    left: 13%;
    top: -20px;
    animation: spinx 5s infinite;
  }

  .fudo-animated-img-two {
    left: 60%;
    animation: spinx 5s infinite;
  }

  .fudo-animated-img-five {
    left: 70%;
    bottom: 50px;

  }

  .fudo-animated-img-four {
    left: -15%;
    top: 59%;
    animation: spinx 8s infinite;

  }

  .fudo-animated-img-three {
    left: -9%;
    top: -7%;
    animation: spinx 9s infinite;
  }

}

@media only screen and (min-width: 1500px) and (max-width: 1699px) {
  .fudo-animated-img-one {
    left: 21%;
    top: -20px;
    animation: spinx 5s infinite;
  }

  .fudo-animated-img-two {
    left: 60%;
    animation: spinx 5s infinite;
  }

  .fudo-animated-img-five {
    left: 70%;
    bottom: 50px;

  }

  .fudo-animated-img-four {
    left: -9%;
    top: 59%;
    animation: spinx 8s infinite;

  }

  .fudo-animated-img-three {
    left: -4%;
    top: -7%;
    animation: spinx 9s infinite;
  }
}
</style>
